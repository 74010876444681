var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"bread"},[_c('span',{class:{ active: _vm.menuChild === '' }},[_vm._v(_vm._s(_vm.menuParent))]),(_vm.menuChild !== '')?_c('i',{staticClass:"el-icon-arrow-right"}):_vm._e(),_c('span',{class:{
          active: _vm.menuGrandChild === '',
          'hover-color': _vm.menuGrandChild !== ''
        },on:{"click":function($event){return _vm.gotoMenuChild(_vm.menuChild)}}},[_vm._v(" "+_vm._s(_vm.menuChild)+" ")]),(_vm.menuGrandChild !== '')?_c('i',{staticClass:"el-icon-arrow-right"}):_vm._e(),_c('span',{class:{ active: _vm.menuGrandChild !== '' }},[_vm._v(" "+_vm._s(_vm.menuGrandChild)+" ")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"news"},[_c('div',{on:{"click":function($event){$event.stopPropagation();_vm.isShowNews = !_vm.isShowNews}}},[_c('el-badge',{attrs:{"value":_vm.newsNum,"max":99,"hidden":_vm.newsNum < 1}},[_c('SvgIcon',{attrs:{"iconClass":"bell","className":"icon"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowNews),expression:"isShowNews"}],ref:"showNews",staticClass:"news-list"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"nav-list"},_vm._l((_vm.barList),function(item){return _c('div',{key:item.id,staticClass:"bar",class:_vm.hoverBar === item.id || _vm.clickBar === item.id
                  ? 'bar-active'
                  : '',on:{"mouseenter":function($event){return _vm.doHoverBar(item.id)},"mouseleave":_vm.leaveBar,"click":function($event){return _vm.doClickBar(item)}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverBar === item.id || _vm.clickBar === item.id),expression:"hoverBar === item.id || clickBar === item.id"}],staticClass:"block"}),_c('span',{staticClass:"label",class:_vm.hoverBar === item.id || _vm.clickBar === item.id
                    ? 'label-active'
                    : ''},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.count > 0)?_c('span',{staticClass:"cicle"}):_vm._e()])}),0)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.infoLoading),expression:"infoLoading"}],staticClass:"cont"},[(_vm.newsList && _vm.newsList.length > 0)?_c('div',{staticClass:"cont-list"},_vm._l((_vm.newsList),function(item){return _c('div',{key:item.id,staticClass:"cont-item",on:{"click":function($event){return _vm.gotoDetail(item)},"mouseenter":function($event){_vm.hoverNews = item.id},"mouseleave":function($event){_vm.hoverNews = -1}}},[_c('div',{class:!item.haveRead ? 'unread' : 'desc'},[_c('p',{staticClass:"text",class:_vm.hoverNews === item.id ? 'text-active' : ''},[_vm._v(" "+_vm._s(item.content)+" ")]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(item.createdTime))])])])}),0):_vm._e(),(!_vm.newsList || (_vm.newsList && _vm.newsList.length === 0))?_c('div',{staticClass:"empty"},[_vm._v(" 暂无数据 ")]):_vm._e()])])]),_c('div',{staticClass:"avatar"}),_c('div',{staticClass:"name"},[_vm._v("欢迎，"+_vm._s(_vm.user.username))]),_c('div',{staticClass:"more",on:{"click":function($event){$event.stopPropagation();_vm.isShowMore = !_vm.isShowMore
        _vm.isShowNews = false}}},[_c('SvgIcon',{attrs:{"iconClass":"more","className":"icon"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowMore),expression:"isShowMore"}],ref:"showMore",staticClass:"layer"},[_c('div',{staticClass:"item",class:_vm.action === 'pwd' ? 'item-active' : '',on:{"mouseenter":function($event){return _vm.enter('pwd')},"mouseleave":_vm.leave,"click":_vm.doEdit}},[_vm._v(" 修改密码 ")]),_c('div',{staticClass:"item",class:_vm.action === 'logout' ? 'item-active' : '',on:{"mouseenter":function($event){return _vm.enter('logout')},"mouseleave":_vm.leave,"click":_vm.loginOut}},[_vm._v(" 退出登录 ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }