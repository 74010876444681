export const carinfo = {
  carType: [
    {
      value: '1',
      label: '全部'
    },
    {
      value: '2',
      label: '产权车'
    },
    {
      value: '3',
      label: '月租车'
    }
  ],
  carportType: [
    {
      value: '1',
      label: '全部类型'
    },
    {
      value: '2',
      label: '业主车位'
    },
    {
      value: '3',
      label: '人防车位'
    }
  ],
  carportState: [
    {
      value: '',
      label: '全部车位'
    },
    {
      value: '2',
      label: '已用'
    },
    {
      value: '3',
      label: '未用'
    }
  ],
  searchType: [
    {
      value: 'carNo',
      label: '车牌号'
    },
    {
      value: 'houseInfo',
      label: '房屋信息'
    },
    {
      value: 'remark',
      label: '备注'
    }
  ],
  searchType2: [
    {
      value: 'houseNo',
      label: '所属房屋'
    },
    {
      value: 'carPlaceNo',
      label: '车位编号'
    }
  ],
  carRecordSearch1: [
    {
      value: 'carNo',
      label: '车牌号'
    },
    {
      value: 'remark',
      label: '备注'
    }
  ],
  carRecordLog: [
    {
      value: 'startTime',
      label: '识别时间'
    }
  ],
  carRecordLogSearch2: [
    {
      value: 'carNo',
      label: '车牌号'
    }
  ]
}
