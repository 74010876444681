





































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Watch, Vue, Prop, PropSync } from 'vue-property-decorator'
import PasswordModal from '@/views/common/password-modal.vue'
import { Avatar, Badge } from 'element-ui'
Vue.use(Avatar)
Vue.use(Badge)

@Component({
  components: {
    PasswordModal
  }
})
export default class Header extends Vue {
  @Prop() private path!: any
  @PropSync('barListSync', {
    type: Array,
    default: []
  })
  barList: any
  @PropSync('newsNumSync', {
    type: Number,
    default: 0
  })
  newsNum: any

  $constants: any
  menuParent = ''
  menuChild = ''
  menuGrandChild = ''
  isShowMore = false
  isShowNews = false
  action = ''
  user = [] as any
  infoLoading = false
  newsList = []
  hoverBar = 1
  clickBar = 1
  hoverNews = -1
  tempType = ''

  // 监听器
  @Watch('path')
  getpath() {
    this.getRouterName()
  }

  @Watch('$route')
  getRouter(to: any, from: any) {
    if (to.meta.isDetail) {
      this.menuGrandChild = to.name
      this.menuChild = from.name
    }
    if (
      from.name === '信息详情' &&
      from.path === '/property/news-history-detail'
    ) {
      this.menuGrandChild = '历史信息'
      this.menuChild = '信息发布'
    }
  }

  @Watch('isShowNews')
  getIsShowNews(val: any) {
    if (val) {
      this.getNoticeInfoLists({
        type: this.tempType,
        userId: this.user.id
      })
    }
  }

  created() {
    this.getRouterName()
  }

  mounted() {
    const u = sessionStorage.getItem('user')
    this.user = u && JSON.parse(u)
    document.addEventListener('click', (e: any) => {
      if (this.$refs.showNews) {
        const isSelf = (this.$refs.showNews as any).contains(e.target)
        if (!isSelf) {
          this.isShowNews = false
        }
      }
      if (this.$refs.showMore) {
        const isSelf = (this.$refs.showMore as any).contains(e.target)
        if (!isSelf) {
          this.isShowMore = false
        }
      }
    })
    this.tempType = 'OWNER_AUDIT'
    this.getNoticeInfoLists({
      type: 'OWNER_AUDIT',
      userId: this.user.id
    })
  }

  go2Gate() {
    this.$router.push('/gate')
  }

  getRouterName() {
    this.menuParent = ''
    this.menuChild = ''
    this.menuGrandChild = ''
    const path = this.$route.path
    this.$constants.menulist.map((menu: any) => {
      if (menu.path === path) {
        this.menuParent = menu.name
        return
      } else {
        if (menu.children) {
          menu.children.map((child: any) => {
            if (child.path === path) {
              this.menuParent = menu.name
              this.menuChild = child.name
              return
            } else {
              if (child.children) {
                child.children.map((grandchild: any) => {
                  if (grandchild.path === path) {
                    this.menuParent = menu.name
                    this.menuChild = child.name
                    this.menuGrandChild = grandchild.name
                    return
                  }
                })
              }
            }
          })
        }
      }
    })
  }
  gotoMenuChild(menuChild: any) {
    this.$constants.menulist.forEach((m: any) => {
      if (m.children && m.children.length > 0) {
        let path: any = {}
        path = m.children.filter((i: any) => i.meta.title === menuChild)
        if (path && path.length > 0) {
          this.$router.push(path[0].path)
        }
      }
    })
  }

  loginOut() {
    this.$api.login.logout().then((res: any) => {
      if (res.data.success) {
        this.$router.push('/')
        this.$message.success(res.data.msg)
        sessionStorage.clear()
        localStorage.clear()
      }
    })
  }
  doEdit() {
    this.$emit('openDialog')
  }

  enter(action: string) {
    this.action = action
  }

  leave() {
    this.action = ''
  }

  doHoverBar(id: number) {
    this.hoverBar = id
  }

  leaveBar() {
    this.hoverBar = -1
  }

  doClickBar(item: any) {
    this.clickBar = item.id
    this.tempType = item.value
    const p = {
      type: item.value,
      userId: this.user.id
    }
    this.getNoticeInfoLists(p)
    this.$emit('clickBar', item)
  }

  gotoDetail(item: any) {
    this.$api.home
      .checkedNotice({
        noticeId: item.id,
        userId: this.user.id
      })
      .then((res: any) => {
        if (res.data.success) {
          this.isShowNews = false
          this.$emit('refresh', item)
        }
      })

    if (item.type === 'OWNER_AUDIT') {
      // 业主审核
      this.$router.push('/residents/owner-check-detail?id=' + item.dataId)
    } else if (item.type === 'TENANT_AUDIT') {
      // 租客审核
      this.$router.push('/residents/tenant-check-detail?id=' + item.dataId)
    }
    // else if (item.type === 'REPAIR_APPLY') {
    //   // 报修管理
    //   this.$router.push('/property/repair-detail?id=' + item.dataId)
    // }
    else if (item.type === 'OWNER_OPINION') {
      // 业主意见
      this.$router.push('/property/opinion')
    }
  }

  getNoticeInfoLists(params: any) {
    this.infoLoading = true
    this.$api.home
      .getNoticeInfoList(params)
      .then((res: any) => {
        this.infoLoading = false
        if (res.data.success) {
          this.newsList = res.data.data
        }
      })
      .catch(() => {
        this.infoLoading = false
      })
  }
}
