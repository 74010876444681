/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 车辆管理接口列表
 */
import axios from './http'

export const car = {
  // --- 车辆列表模块 ---
  // 车辆列表
  getCarList(params: any) {
    return axios.get('/api/c-pc/car/list/page', { params })
  },
  // 修改车辆到期时间
  editDueDate(params: any) {
    return axios.post('/api/c-pc/car/update/dueDate', params)
  },
  // 新增车辆
  addCar(params: any) {
    return axios.post('/api/c-pc/car/add', params)
  },
  // 根据id查询车辆信息
  getCarInfo(id: any) {
    return axios.get(`/api/c-pc/car/get/${id}`)
  },
  // 根据id修改车辆信息
  editCarInfo(params: any) {
    return axios.put(`/api/c-pc/car/update`, params)
  },
  // 根据id删除车辆
  deleteCar(id: any) {
    return axios.delete(`/api/c-pc/car/delete/${id}`)
  },
  // 批量删除车辆
  batchDeleteCar(params: any) {
    return axios.post('/api/c-pc/car/batch/delete', params)
  },

  // --- 车辆列表模块 ---
  // 车位列表
  getCarportList(params: any) {
    return axios.get('/api/c-pc/attachment/car-place/list/page', {
      params
    })
  },
  // 新增车位
  addCarport(params: any) {
    return axios.post('/api/c-pc/attachment/car-place/add', params)
  },
  // 批量导入已解析车位
  batchImportCatport(params: any) {
    return axios.post('/api/c-pc/attachment/car-place/import', params)
  },
  // 根据id查询车位
  getCarportInfo(id: number) {
    return axios.get(`/api/c-pc/attachment/car-place/get/${id}`)
  },
  // 根据id修改车位
  editCarportInfo(params: any) {
    return axios.put('/api/c-pc/attachment/car-place/update', params)
  },
  // 根据id删除车位
  deleteCarport(id: any) {
    return axios.delete(`/api/c-pc/attachment/car-place/delete/${id}`)
  },
  // 批量删除车位
  batchDeleteCarport(params: any) {
    return axios.post('/api/c-pc/attachment/car-place/batch/delete', params)
  },
  // 获取车位编号
  getCarportNumber(params: any) {
    return axios.get('/api/c-pc/attachment/car-place/area/validSerialNumber', {
      params
    })
  },

  // --- 进出记录模块 ---
  // 进出记录列表
  getRecordList(params: any) {
    return axios.get('/api/c-pc/attachment/car-travel-record/list/page', {
      params
    })
  },
  // 根据车牌号查询车辆进出历史详情
  getCarHistoryInfo(carNo: string) {
    return axios.get(
      `/api/c-pc/attachment/car-travel-record/get/hisInfo/${carNo}`
    )
  },

  // --- 识别记录模块 ---
  // 获取车辆识别日志接口
  getCarTravelLog(params: any) {
    return axios.get('/api/c-pc/visit/car/carTravelLog/page', {
      params
    })
  }
}
